export function getOpenEdgeFormStyles({
  embeddedInCustomerPortal,
  customerPortalButtonColor,
  customerPortalButtonTextColor,
}) {
  return {
    styles: {
      body: {
        "font-family":
          'BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif',
        "font-weight": "500",
      },
      button: embeddedInCustomerPortal
        ? {
            "background-color": customerPortalButtonColor,
            "border-radius": "6px",
            width: "100% !important",
            height: "50px",
            "font-size": "1.3rem",
            "font-weight": "700",
            color: customerPortalButtonTextColor,
          }
        : {
            "background-color": "rgb(0, 144, 219)",
            border: "1px solid white",
            "border-radius": "4px",
            color: "white",
            flex: "none !important",
            "font-size": "16px",
            height: "40px",
            "line-height": "24px",
            padding: "7px 16px",
            width: "225px !important",
          },
      "button:active": {
        "background-color": "#007fc2",
      },
      "button:focus": {
        border: "1px solid transparent",
        "box-shadow": "0 0 0 0.125em rgba(0, 144, 219, 0.25);",
        outline: "none",
      },
      input: {
        "-webkit-font-smoothing": "antialiased",
        border: "1px solid transparent",
        "border-color": "#e5e7eb", // gray-200
        "border-radius": "4px",
        "box-shadow": "inset 0 0.0625em 0.125em rgba(10, 10, 10, 0.05)",
        "font-size": "1rem",
        height: embeddedInCustomerPortal ? "40px" : "24px",
        padding: "7px 11px",
      },
      "input:focus": {
        "border-color": "#1d4ed8", // blue-600
        "border-width": "1.5px",
        outline: "none",
      },
      "input.invalid": {
        border: "1px solid #dc2626", // red-600
      },
    },
  };
}
