import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cron"
export default class extends Controller {
  static targets = ["repeats", "timeOfDay", "dayOfWeek", "dayOfMonth", "cadence"];

  connect() {
    const cron = this.cadenceTarget.value.split(" ");
    this.repeatsTarget.value = cron[2] !== "*" ? "monthly" : cron[4] !== "*" ? "weekly" : "daily";
    this.timeOfDayTarget.value = `${cron[1]}:${cron[0]}`;
    this.dayOfWeekTarget.value = cron[4] === "*" ? undefined : cron[4];
    this.dayOfMonthTarget.value = cron[2] === "*" ? undefined : cron[2];
    this.updateFields();
  }

  onChangeTime() {
    this.calculateCadence();
  }

  onChangeRepeat() {
    this.updateFields();
    this.calculateCadence();
  }

  updateFields() {
    const repeatFrequency = this.repeatsTarget.value;
    this.dayOfWeekTarget.hidden = repeatFrequency !== "weekly";
    this.dayOfMonthTarget.hidden = repeatFrequency !== "monthly";
  }

  calculateCadence() {
    let cadence = "";
    const time = this.timeOfDayTarget.value.split(":");
    const hour = time[0];
    const minutes = time[1] === "30" ? "30" : "0";

    cadence += minutes + " " + hour;

    if (this.repeatsTarget.value === "monthly") {
      cadence += " " + this.dayOfMonthTarget.value;
    } else {
      cadence += " *";
    }

    if (this.repeatsTarget.value === "weekly") {
      cadence += " * " + this.dayOfWeekTarget.value;
    } else {
      cadence += " * *";
    }

    this.cadenceTarget.value = cadence; // Assuming you have an input to hold the cadence value
  }
}
