import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "customError",
    "customErrorText",
    "firstName",
    "form",
    "isSubmittingSpinner",
    "lastName",
    "submit",
  ];

  showSpinner() {
    this.isSubmittingSpinnerTarget.hidden = false;
  }

  hideSpinner() {
    this.isSubmittingSpinnerTarget.hidden = true;
  }

  /*
   * functions used by other controllers
   */
  firstName() {
    return this.lastNameTarget?.value;
  }

  lastName() {
    return this.lastNameTarget?.value;
  }

  disableSubmit() {
    this.submitTarget.disabled = true;
  }

  enableSubmit() {
    this.submitTarget.disabled = false;
  }

  showSubmit() {
    this.submitTarget.hidden = false;
  }

  onFormSubmit(func) {
    this.formTarget.addEventListener("submit", func);
  }

  formRequestSubmit() {
    this.formTarget.requestSubmit();
  }

  setCustomError(message) {
    this.customErrorTarget.hidden = !message;
    this.customErrorTextTarget.innerText = message || "";
  }
  /*
   * end functions used by other controllers
   */
}
